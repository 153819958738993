import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';

const RisksPage = ({ data }) => (
  <Layout>
    <SEO title="Risks" />
    <div className="container page pt-16 pb-20 lg:max-w-lg body-content">
      <h1 className="hero-title mb-8 md:mb-12 lg:text-center">
        Risks and Limitations of Liability
      </h1>
      <p>
        When restoring jerseys, there is always the slight risk of causing
        damage that cannot be repaired invisibly.
      </p>
      <p>
        <b>You must assume this risk.</b> While I am skilled at this task,
        please understand this possibility before we begin. This is why your
        valuable items should always be insured.
      </p>
      <p>
        When removing lettering, I can generally tell early if it is going to
        come off without leaving marks or holes, but sometimes, especially on
        newer, CoolBase or color-sublimated fabric, we don’t know until the
        after damage has occurred.
      </p>
      <p>
        Removing glue and stains always has risks. I have experience with the
        chemicals I use, and test first on an inconspicuous area but it is
        possible that fabric may be discolored or old stains may remain.
      </p>
      <p>
        If I suspect the project may fail, I will stop at once and ask your
        advice. The remedy is generally to re-sew the old lettering back, or
        recreate lettering that covers the old marks. There is a charge for
        this.{' '}
      </p>
      <p>
        I always ship my packages <em>insured</em> and recommend that you do the
        same. Yes, it is more expensive but nothing is worse than expecting an
        expensive package that never arrives.
      </p>
      <figure className="md:hidden">
        <div className="flex flex-col mt-8">
          {data.allFile.edges.map(edge => (
            <div className="flex-1 mb-4">
              <Img fluid={edge.node.childImageSharp.fluid} />
            </div>
          ))}
        </div>
        <figcaption className="leading-normal italic">
          Here, an example of hidden damage on a Minor-League repurposed jersey
          that required extra skill to repair. While the results here were near
          perfect, not all damage can be invisibly repaired.
        </figcaption>
      </figure>
      <figure className="hidden md:block mt-12">
        <div className="md:flex flex-row flex-wrap justify-between">
          {data.allFile.edges.map(edge => (
            <div className="mb-3" style={{ flex: '0 0 49%' }}>
              <Img fluid={edge.node.childImageSharp.fluid} />
            </div>
          ))}
        </div>
        <figcaption className="leading-normal italic">
          Here, an example of hidden damage on a Minor-League repurposed jersey
          that required extra skill to repair. While the results here were near
          perfect, not all damage can be invisibly repaired.
        </figcaption>
      </figure>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    allFile(
      filter: { name: { glob: "mariners-*" } }
      sort: { order: ASC, fields: [name] }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;

export default RisksPage;
